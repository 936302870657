<template>
  <section class="profile-info">
    <header
      v-if="title"
      class="profile-info__header"
    >
      <h2>{{ title }}</h2>

      <VBtn
        v-if="!readonly"
        :to="{name: 'user-edit', query: { settings: anchor }}"
        class="ml-2"
        x-small
        text
        color="primary"
      >
        {{ $t('edit.one') }}
      </VBtn>
    </header>

    <div>
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: 'ProfileInfo',
  inheritAttrs: false,

  props: {
    title: {
      type: String,
      default: ''
    },

    readonly: {
      type: Boolean,
      default: true
    },

    anchor: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
  .profile-info {
    margin-top: 1.5rem;
  }

  .profile-info__header {
    font-size: map-deep-get($headings, 'h3', 'font-size');
    display: flex;
    margin-bottom: 2rem;
  }
</style>
